<template>
  <!--begin::Charts-->
  <div class="card" :class="widgetClasses">
    <!--begin::Body-->
    <div class="card-body p-0">
      <!--begin::Chart-->
      <apexchart
        :options="options"
        :series="series"
        :id="widgetId"
        height="250"
      ></apexchart>
      <!--end::Chart-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Charts-->
</template>

<script>
import { defineComponent, onMounted, ref, watch } from "vue";

export default defineComponent({
  name: "ResumeGrafik2",
  props: {
    widgetClasses: String,
    widgetData: { type: Object, required: true },
    widgetId: { type: String, required: true },
  },
  setup(props) {
    const options = {
      chart: {
        id: props.widgetId,
        width: 380,
        type: "pie",
      },
      labels: ["Tidak Tercapai", "Tercapai"],
      colors: ["#FF5001", "#6B2100"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };

    const series = ref([0, 0]);

    const dummy = ref({});

    onMounted(() => {
      setTimeout(() => {
        for (let index = 0; index < 1; ) {
          if (props.widgetData && props.widgetData[0] > 0) {
            updateChart();
            index++;
          }
        }
      }, 100);
    });

    watch(props.widgetData, () => {
      updateChart();
    });

    const updateChart = () => {
      // console.log(props.widgetId, props.widgetData);
      dummy.value.data = props.widgetData.map((item) => {
        return item;
      });
      series.value = Object.values(dummy.value.data);
    };

    return {
      options,
      series,
    };
  },
});
</script>
