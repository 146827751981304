<template>
  <!--begin::Charts-->
  <div class="card" :class="widgetClasses">
    <!--begin::Body-->
    <div class="card-body p-0">
      <!--begin::Chart-->
      <div class="chartdiv" ref="chartdiv" style="height: auto"></div>
      <!--end::Chart-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Charts-->

  <button
    id="open-modal-caleg"
    class="d-none"
    data-bs-toggle="modal"
    data-bs-target="#modal-caleg"
  ></button>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useStore } from "vuex";

am4core.useTheme(am4themes_animated);

export default defineComponent({
  name: "Grafik-Resume",
  props: {
    widgetClasses: String,
    widgetId: String,
    widgetData: Object,
  },
  setup(props) {
    const chartdiv = ref(null);

    const path = window.location.origin + "/other/png/partai/";

    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      setTimeout(() => {
        for (let index = 0; index < 1; ) {
          if (props.widgetData && props.widgetData.length) {
            updateChart();
            index++;
          }
        }
      }, 100);
    });

    const updateChart = () => {
      const chart = am4core.create(chartdiv.value, am4charts.XYChart);

      chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

      chart.paddingRight = 40;

      const dummy = ref([]);
      const year = ref(2019);

      props.widgetData.map((item) => {
        dummy.value.push({
          id: item.party.id,
          name: item.party.name,
          suara: item.party.count,
          href: path + item.party.logo,
        });
        year.value = item.party.year;
      });

      chart.data = dummy.value;

      let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "name";
      categoryAxis.renderer.grid.template.strokeOpacity = 0;
      categoryAxis.renderer.minGridDistance = 10;
      categoryAxis.renderer.labels.template.dx = -40;
      categoryAxis.renderer.minWidth = 120;
      categoryAxis.renderer.tooltip.dx = -40;

      let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.inside = true;
      valueAxis.renderer.labels.template.fillOpacity = 0.3;
      valueAxis.renderer.grid.template.strokeOpacity = 0;
      valueAxis.min = 0;
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.renderer.baseGrid.strokeOpacity = 0;
      valueAxis.renderer.labels.template.dy = 20;

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = "suara";
      series.dataFields.categoryY = "name";
      series.tooltipText = "{valueX.value}";
      series.tooltip.pointerOrientation = "vertical";
      series.tooltip.dy = -30;
      series.columnsContainer.zIndex = 100;

      let columnTemplate = series.columns.template;
      columnTemplate.height = am4core.percent(50);
      columnTemplate.maxHeight = 50;
      columnTemplate.column.cornerRadius(10, 60, 10, 60);
      columnTemplate.strokeOpacity = 0;

      columnTemplate.events.on(
        "hit",
        function (ev) {
          const id = ev.target.dataItem.dataContext.id;

          store.commit("SET_PARAMS_CANDIDATE_RESUME", {
            year: year.value,
            id: id,
          });
          let aa = document.getElementById("open-modal-caleg");
          aa.click();
        },
        this
      );

      series.tooltipText = "{valueX.value}";
      chart.cursor = new am4charts.XYCursor();

      series.heatRules.push({
        target: columnTemplate,
        property: "fill",
        dataField: "valueX",
        max: am4core.color("#FE5001"),
        min: am4core.color("#FEB67A"),
      });
      series.mainContainer.mask = undefined;

      let cursor = new am4charts.XYCursor();
      chart.cursor = cursor;
      cursor.lineX.disabled = true;
      cursor.lineY.disabled = true;
      cursor.behavior = "none";

      let bullet = columnTemplate.createChild(am4charts.CircleBullet);
      bullet.circle.radius = 30;
      bullet.valign = "middle";
      bullet.align = "left";
      bullet.isMeasured = true;
      bullet.interactionsEnabled = false;
      bullet.horizontalCenter = "right";
      bullet.interactionsEnabled = false;

      let hoverState = bullet.states.create("hover");
      let outlineCircle = bullet.createChild(am4core.Circle);
      outlineCircle.adapter.add("radius", function (radius, target) {
        let circleBullet = target.parent;
        return circleBullet.circle.pixelRadius;
      });

      let image = bullet.createChild(am4core.Image);
      image.width = 60;
      image.height = 60;
      image.horizontalCenter = "middle";
      image.verticalCenter = "middle";
      image.propertyFields.href = "href";

      image.adapter.add("mask", function (mask, target) {
        let circleBullet = target.parent;
        return circleBullet.circle - 5;
      });

      let previousBullet;
      chart.cursor.events.on("cursorpositionchanged", function (event) {
        let dataItem = series.tooltipDataItem;

        if (dataItem.column) {
          let bullet = dataItem.column.children.getIndex(1);

          if (previousBullet && previousBullet != bullet) {
            previousBullet.isHover = false;
          }

          if (previousBullet != bullet) {
            let hs = bullet.states.getKey("hover");
            hs.properties.dx = dataItem.column.pixelWidth;
            bullet.isHover = true;

            previousBullet = bullet;
          }
        }
      });

      // set height dinamic
      let cellSize = 70;

      // Calculate how we need to adjust chart height
      let adjustHeight =
        chart.data.length * cellSize - categoryAxis.pixelHeight;

      // get current chart height
      let targetHeight = chart.pixelHeight + adjustHeight;

      // Set it on chart's container
      chart.svgContainer.htmlElement.style.height = targetHeight + "px";
    };

    const backMenu = () => {
      router.push({ name: "heat-maps" });
    };

    return {
      chartdiv,
      backMenu,
    };
  },
});
</script>
