<template>
  <!--begin::Charts Widget 1-->
  <div class="card" :class="widgetClasses">
    <!--begin::Body-->
    <div class="card-body">
      <div class="row">
        <div class="col-12 d-flex align-items-center mb-2">
          <img :src="path + party.logo" alt="" />
          <span class="fw-bolder ms-4">
            Jumlah Suara: {{ Intl.NumberFormat().format(party.suara) }}
          </span>
        </div>
        <div class="col-md-12">
          <!--begin::Chart-->
          <apexchart
            id="chart-candidate"
            :options="options"
            :series="series"
            @dataPointSelection="clickHandler"
          ></apexchart>
          <!--end::Chart-->
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Charts Widget 1-->
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import ApexCharts from "apexcharts";
import { useStore } from "vuex";

export default defineComponent({
  name: "Grafik-Suara",
  props: {
    widgetClasses: String,
  },
  setup() {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const baseColor = getCSSVariableValue("--bs-default");
    const secondaryColor = getCSSVariableValue("--bs-gray-300");
    const path = window.location.origin + "/other/png/partai/";

    const store = useStore();

    const params = computed(() => store.state.ResumeModule.paramsCandidate);

    const data = computed(() => store.state.ResumeModule.candidate);

    const party = ref({
      name: "",
      logo: "",
      suara: "",
    });

    watch(params, () => {
      store
        .dispatch("getCountCandidateResume", {
          year: params.value.year,
          party: params.value.id,
        })
        .then(() => {
          party.value.name = data.value.party.name;
          party.value.logo = data.value.party.logo;
          party.value.suara = data.value.party.count;
          updateChart();
        });
    });

    watch(data, () => {
      updateChart();
      if (data.value.party) {
        party.value.logo = data.value.party.logo;
        party.value.suara = data.value.party.count;
      }
    });

    const options = {
      chart: {
        id: "chart-candidate",
        fontFamily: "inherit",
        height: "auto",
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: ["30%"],
          endingShape: "rounded",
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return Intl.NumberFormat().format(val);
        },
        offsetX: 50,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: ["Caleg 1", "Caleg 2", "Caleg 3", "Caleg 4", "Caleg 5"],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      fill: {
        opacity: 1,
        color: ["#FF5001"],
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return "Suara: " + Intl.NumberFormat().format(val);
          },
        },
      },
      colors: [baseColor, secondaryColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    };

    const series = [
      {
        name: "Jumlah",
        data: [44, 55, 57, 61, 58],
      },
    ];

    const updateChart = () => {
      const dummy = ref({
        name: [],
        data: [],
      });

      if (data.value.candidates) {
        dummy.value.name = data.value.candidates.map((item) => {
          return item.name;
        });
        dummy.value.data = data.value.candidates.map((item) => {
          return item.count;
        });
      } else {
        party.value.suara = 0;
      }

      const categories = Object.values(dummy.value.name);

      const nilai = Object.values(dummy.value.data);

      ApexCharts.exec("chart-candidate", "updateOptions", {
        xaxis: {
          categories: categories,
        },
      });
      ApexCharts.exec("chart-candidate", "updateOptions", {
        chart: {
          height: 20 * nilai.length,
        },
      });      
        ApexCharts.exec(
          "chart-candidate",
          "updateSeries",
          [
            {
              data: nilai,
            },
          ],
          true
        );
    };

    const clickHandler = (event, chartContext, config) => {
      const index = config.dataPointIndex;
    };

    return {
      options,
      series,
      party,
      path,
      clickHandler,
    };
  },
});
</script>
