<template>
  <div class="card bg-light shadow card-xxl-stretch mb-5 mb-xl-10">
    <div class="card-body p-3">
      <div class="row gy-5 g-xl-8">
        <div class="col-xl-8">
          <div class="card-p position-relative">
            <!--begin::Row-->
            <div class="row m-0">
              <div
                class="col px-6 py-8 rounded-3 me-7 mb-7"
                style="background-color: #6b2100"
              >
                <span
                  class="svg-icon svg-icon-4x svg-icon-white d-block mb-3 mt-n5"
                >
                  <inline-svg
                    :src="path + 'other/svg/vuesax-bold-people.svg'"
                  />
                </span>
                <span class="fsx-1 text-white fw-bold me-2">
                  {{ countRelawan }}
                </span>
                <span class="fs-1 text-white">Total Relawan</span>
              </div>
              <div class="col bg-default px-6 py-8 rounded-3 me-7 mb-7">
                <span
                  class="svg-icon svg-icon-4x svg-icon-white d-block mb-3 mt-n5"
                >
                  <inline-svg
                    :src="path + 'other/svg/vuesax-bold-presention-chart.svg'"
                  />
                </span>
                <span class="fsx-1 text-white fw-bold me-2">
                  {{ countActivity }}
                </span>
                <span class="fs-1 text-white">Aktivitas</span>
              </div>
            </div>
          </div>

          <div class="card mb-8">
            <div
              class="d-flex justify-content-between align-items-center my-5 m-2"
            >
              <h3 class="fw-bolder m-0">Cari Wilayah</h3>
              <div
                class="
                  float-end
                  d-flex
                  align-items-right
                  justify-content-between
                  w-75
                "
              >
                <select
                  name=""
                  id=""
                  class="form-select me-2"
                  @input="getKecamatan"
                  v-model="formData.kecamatan"
                >
                  <option value="" selected>Pilih Kecamatan</option>
                  <option
                    v-for="(item, i) in kecamatan"
                    :key="i"
                    :value="item.territory.id"
                  >
                    {{ item.territory.name }}
                  </option>
                </select>
                <select
                  name=""
                  id=""
                  class="form-select me-2"
                  @input="getKelurahan"
                  v-model="formData.kelurahan"
                >
                  <option value="" selected>Pilih Kelurahan</option>
                  <template v-if="kelurahan">
                    <option
                      v-for="(item, i) in kelurahan"
                      :key="i"
                      :value="item.territory.id"
                    >
                      {{ item.territory.name }}
                    </option>
                  </template>
                </select>
              </div>
            </div>
            <template v-if="party1 && party1.length">
              <!--begin::Header-->
              <div class="card-header align-items-center border-0 mt-n2">
                <h3 class="card-title align-items-start flex-column">
                  <span class="fw-bolder mb-2 text-muted"
                    >Perolehan Suara Partai & Caleg {{ year }}
                  </span>
                </h3>
              </div>
              <!--end::Header-->
              <div class="card-body my-3 p-0">
                <div class="row">
                  <div
                    class="
                      col-4
                      d-flex
                      align-items-center
                      justify-content-center
                      flex-column
                    "
                  >
                    <div class="card card-stretch bg-light-default">
                      <div class="card-body">
                        <img
                          :src="path + 'other/png/vuesax-bold-graph-1.png'"
                          alt="gambar"
                          class="img-fluid bg-light-default"
                        />
                        <div class="fw-bolder fs-5 text-default">
                          Hasil Real Count
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-8">
                    <widget-1
                      widget-classes="card-xl-stretch"
                      :widget-id="'resumeG1'"
                      :widget-data="party1"
                    />
                  </div>
                </div>
              </div>
            </template>
            <template v-if="party2 && party2.length">
              <!--begin::Header-->
              <div class="card-header align-items-center border-0 mt-n2">
                <h3 class="card-title align-items-start flex-column">
                  <span class="fw-bolder mb-2 text-muted"
                    >Perolehan Suara Partai & Caleg {{ year + 5 }}
                  </span>
                </h3>
              </div>
              <!--end::Header-->
              <div class="card-body my-3 p-0">
                <div class="row">
                  <div
                    class="
                      col-4
                      d-flex
                      align-items-center
                      justify-content-center
                      flex-column
                    "
                  >
                    <div class="card card-stretch bg-light-default">
                      <div class="card-body">
                        <img
                          :src="path + 'other/png/vuesax-bold-graph-1.png'"
                          alt="gambar"
                          class="img-fluid bg-light-default"
                        />
                        <div class="fw-bolder fs-5 text-default">
                          Hasil Real Count
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-8">
                    <widget-1
                      widget-classes="card-xl-stretch"
                      :widget-id="'resumeG2'"
                      :widget-data="party2"
                    />
                  </div>
                </div>
              </div>
            </template>
          </div>

          <div class="card mb-8">
            <div
              class="d-flex justify-content-between align-items-center my-5 m-2"
            >
              <h3 class="fw-bolder m-0">Cari Wilayah</h3>
              <div
                class="
                  float-end
                  d-flex
                  align-items-right
                  justify-content-between
                  w-75
                "
              >
                <select
                  name=""
                  id=""
                  class="form-select me-2"
                  @input="getKecamatan"
                  v-model="formData.kecamatan"
                >
                  <option value="" selected>Pilih Kecamatan</option>
                  <option
                    v-for="(item, i) in kecamatan"
                    :key="i"
                    :value="item.territory.id"
                  >
                    {{ item.territory.name }}
                  </option>
                </select>
                <select
                  name=""
                  id=""
                  class="form-select me-2"
                  @input="getKelurahan"
                  v-model="formData.kelurahan"
                >
                  <option value="" selected>Pilih Kelurahan</option>
                  <template v-if="kelurahan">
                    <option
                      v-for="(item, i) in kelurahan"
                      :key="i"
                      :value="item.territory.id"
                    >
                      {{ item.territory.name }}
                    </option>
                  </template>
                </select>
              </div>
            </div>
            <div class="card-body p-0">
              <div class="row">
                <div
                  class="
                    col-4
                    d-flex
                    align-items-center
                    justify-content-center
                    flex-column
                  "
                >
                  <div class="card card-stretch bg-light-default">
                    <div class="card-body">
                      <img
                        :src="path + 'other/png/vuesax-bold-money-tick-1.png'"
                        alt="gambar"
                        class="img-fluid bg-light-default"
                      />
                      <div class="fw-bolder fs-5 text-default text-center">
                        Progress Koordinator TPS
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-8">
                  <widget-2
                    v-if="tps && tps[0] > 0"
                    widget-classes="card-xl-stretch"
                    :widget-data="tps"
                    widget-id="data-2"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-8">
            <div
              class="d-flex justify-content-between align-items-center my-5 m-2"
            >
              <h3 class="fw-bolder m-0">Cari Wilayah</h3>
              <div
                class="
                  float-end
                  d-flex
                  align-items-right
                  justify-content-between
                  w-75
                "
              >
                <select
                  name=""
                  id=""
                  class="form-select me-2"
                  @input="getKecamatan"
                  v-model="formData.kecamatan"
                >
                  <option value="" selected>Pilih Kecamatan</option>
                  <option
                    v-for="(item, i) in kecamatan"
                    :key="i"
                    :value="item.territory.id"
                  >
                    {{ item.territory.name }}
                  </option>
                </select>
                <select
                  name=""
                  id=""
                  class="form-select me-2"
                  @input="getKelurahan"
                  v-model="formData.kelurahan"
                >
                  <option value="" selected>Pilih Kelurahan</option>
                  <template v-if="kelurahan">
                    <option
                      v-for="(item, i) in kelurahan"
                      :key="i"
                      :value="item.territory.id"
                    >
                      {{ item.territory.name }}
                    </option>
                  </template>
                </select>
              </div>
            </div>
            <div class="card-body p-0">
              <div class="row">
                <div
                  class="
                    col-4
                    d-flex
                    align-items-center
                    justify-content-center
                    flex-column
                  "
                >
                  <div class="card card-stretch bg-light-default">
                    <div class="card-body">
                      <img
                        :src="path + 'other/png/vuesax-bold-money-tick-1.png'"
                        alt="gambar"
                        class="img-fluid bg-light-default"
                      />
                      <div class="fw-bolder fs-5 text-default text-center">
                        Progress KTANisasi
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-8">
                  <widget-2
                    v-if="ktanisasi && ktanisasi[0] > 0"
                    widget-classes="card-xl-stretch"
                    :widget-data="ktanisasi"
                    widget-id="data-3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <widget-3
            v-if="activity && activity.length"
            widget-classes="shadow mt-xxl-7 mb-5 mb-xl-10 me-xxl-5"
            :widget-data="activity"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="modal-caleg"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <div class="d-flex justify-content-between my-5 m-2">
            <h5 class="fw-bolder m-0">Cari Wilayah</h5>
            <div
              class="d-flex align-items-center justify-content-between w-100"
            >
              <select
                name=""
                id=""
                class="form-select me-2"
                @input="getKecamatan"
                v-model="formData.kecamatan"
              >
                <option value="" selected>Pilih Kecamatan</option>
                <option
                  v-for="(item, i) in kecamatan"
                  :key="i"
                  :value="item.territory.id"
                >
                  {{ item.territory.name }}
                </option>
              </select>
              <select
                name=""
                id=""
                class="form-select me-2"
                @input="getKelurahan"
                v-model="formData.kelurahan"
              >
                <option value="" selected>Pilih Kelurahan</option>
                <template v-if="kelurahan">
                  <option
                    v-for="(item, i) in kelurahan"
                    :key="i"
                    :value="item.territory.id"
                  >
                    {{ item.territory.name }}
                  </option>
                </template>
              </select>
            </div>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <widget-4 widget-classes="shadow" />
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Widget1 from "@/components/custom/resume/widget1.vue";
import Widget2 from "@/components/custom/resume/widget2.vue";
import Widget3 from "@/components/custom/resume/widget3.vue";
import Widget4 from "@/components/custom/resume/widget4.vue";
import { useStore } from "vuex";

export default defineComponent({
  components: { Widget1, Widget3, Widget2, Widget4 },
  setup() {
    setCurrentPageTitle("Resume");

    const store = useStore();

    const year = 2019;

    store.commit("SET_ACTIVE_MENU", "resume");
    store.dispatch("getCountPartyResume", { year: year, type: 1 });
    store.dispatch("getCountPartyResume", {
      year: year + 5,
      type: 2,
    });
    store.dispatch("AllActivityDashboard");
    store.dispatch("getCountRelawanResume", 16);
    store.dispatch("getCountActivityResume");
    store.dispatch("AllTerritory", { type: 4 });
    store.dispatch("getPersentageTPSResume", {  });
    store.dispatch("getPersentageKTAResume");

    const path = window.location.origin + "/";

    const formData = ref({
      kecamatan: "",
      kelurahan: "",
    });

    const relawan = ref([
      {
        name: "Relawan A",
        do: "memberikan",
        about: "Laporan Pelanggaran",
        where: "Daerah B",
        time: "2021-10-10 12:00:30",
        icon: path + "other/svg/vuesax-bold-alarm.svg",
      },
    ]);

    const activity = computed(() => store.state.ActivityDashboardModule.all);

    const tps = computed(() => store.state.ResumeModule.tps);
    const ktanisasi = computed(() => store.state.ResumeModule.ktanisasi);

    const party1 = computed(() => store.state.ResumeModule.party1);
    const party2 = computed(() => store.state.ResumeModule.party2);

    const countRelawan = computed(() => store.state.ResumeModule.relawan);
    const countActivity = computed(() => store.state.ResumeModule.activity);

    const kecamatan = computed(() => store.state.TerritoryModule.kecamatan);
    const kelurahan = computed(() => store.state.TerritoryModule.kelurahan);
    const params = computed(() => store.state.ResumeModule.paramsCandidate);

    watch(tps, () => {
      // // console.log(ktanisasi.value);
    });

    const getKecamatan = (el) => {
      formData.value.kelurahan = "";
      if (el.target.value) {
        store.dispatch("AllTerritory", { type: 5, parent_id: el.target.value });
        updateDataWilayah(el.target.value);
      } else {
        updateTanpaWilayah();
        store.commit("SET_ALL_TERRITORY", { type: 5, territories: [] });
      }
    };

    const getKelurahan = (el) => {
      if (el.target.value) {
        updateDataWilayah(el.target.value);
      } else {
        updateDataWilayah(formData.value.kecamatan);
      }
    };

    const updateDataWilayah = (wilayah) => {
      store.dispatch("AllCadreDashboard", {
        territory_id: wilayah,
      });
      store.dispatch("getCountPartyResume", {
        year: year,
        type: 1,
        territory: wilayah,
      });
      store.dispatch("getCountPartyResume", {
        year: year + 5,
        type: 2,
        territory: wilayah,
      });
      store.dispatch("getPersentageTPSResume", { territory: wilayah });
      store.dispatch("getPersentageKTAResume", { territory: wilayah });
      store.dispatch("getCountCandidateResume", {
        territory: wilayah,
        year: params.value.year,
        party: params.value.id,
      });
    };

    const updateTanpaWilayah = () => {
      store.dispatch("AllCadreDashboard");
      store.dispatch("getCountPartyResume", {
        year: year,
        type: 1,
      });
      store.dispatch("getCountPartyResume", {
        year: year + 5,
        type: 2,
      });
      store.dispatch("getPersentageTPSResume");
      store.dispatch("getPersentageKTAResume");
      store.dispatch("getCountCandidateResume", {
        year: params.value.year,
        party: params.value.id,
      });
    };

    return {
      path,
      relawan,
      party1,
      party2,
      ktanisasi,
      tps,
      activity,
      year,
      formData,
      countRelawan,
      countActivity,
      kecamatan,
      kelurahan,
      getKecamatan,
      getKelurahan,
    };
  },
});
</script>

<style>
.timeline-label::before {
  left: 1px !important;
}
</style>
